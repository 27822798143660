<template>
 <el-card shadow="never">
   <el-form
       :model="ruleForm"
       status-icon
       :rules="rules"
       ref="ruleForm"
       label-width="150px"
       class="demo-ruleForm"
   >
     <el-form-item label="客户编号" prop="id" v-if="false">
       <el-input
           v-model="ruleForm.id"
           autocomplete="off"
           :disabled="true"
       ></el-input>
     </el-form-item>
     <el-form-item label="客户名称" prop="name">
       <el-input v-model="ruleForm.name" autocomplete="off"></el-input>
     </el-form-item>
     <el-form-item label="大类别" prop="levelOneTypeId">
       <el-select v-model="bigTypeId" placeholder="请选择">
         <el-option
             v-for="item in bigOptions"
             :key="item.id"
             :label="item.name"
             :value="item.id"
         >
         </el-option>
       </el-select>
     </el-form-item>
     <el-form-item label="中类别" prop="levelSecondTypeId">
       <el-select v-model="midTypeId" placeholder="请选择">
         <el-option
             v-for="item in midOptions"
             :key="item.id"
             :label="item.name"
             :value="item.id"
         >
         </el-option>
       </el-select>
     </el-form-item>
     <el-form-item
         label="开始服务时间"
         style="width: 150px"
         prop="serverStartTime"
     >
       <el-date-picker
           v-model="ruleForm.serverStartTime"
           value-format="yyyy-MM-dd HH:mm:ss"
           format="yyyy-MM-dd HH:mm:ss"
           type="datetime"
           placeholder="选择日期时间"
       >
       </el-date-picker>
     </el-form-item>
     <el-form-item label="结束服务时间" prop="serverEndTime">
       <el-date-picker
           v-model="ruleForm.serverEndTime"
           value-format="yyyy-MM-dd HH:mm:ss"
           format="yyyy-MM-dd HH:mm:ss"
           type="datetime"
           placeholder="选择日期时间"
       >
       </el-date-picker>
     </el-form-item>
     <el-form-item label="状态" prop="status">
       <el-select v-model="ruleForm.status" placeholder="请选择">
         <el-option
             v-for="item in options"
             :key="item.id"
             :label="item.label"
             :value="item.id"
         >
         </el-option>
       </el-select>
     </el-form-item>
     <el-form-item label="ak" prop="ak">
       <el-input v-model="ruleForm.ak"  autocomplete="off" :disabled="true"></el-input>
     </el-form-item>
     <el-form-item label="sk" prop="sk">
       <el-input v-model="ruleForm.sk" autocomplete="off" :disabled="true" ></el-input>
     </el-form-item>

     <el-form-item>
       <el-button
           type="primary"
           @click="editCustomer('ruleForm')"
           style="background: #1d90ff; border: none"
       >提交
       </el-button
       >
     </el-form-item>
   </el-form>
 </el-card>
</template>
<script>
import $ from "jquery";

export default {
  data() {
    return {
      rules: {
        name: [{required: true, message: "请输入客户名称", trigger: "blur"}],
        levelOneTypeId: [
          {required: true, message: "请选择大类别", trigger: "change"},
        ],
        levelSecondTypeId: [
          {required: true, message: "请选择中类别", trigger: "change"},
        ],
        serverStartTime: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        serverEndTime: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "change",
          },
        ],
      },
      bigTypeId: 0,
      midTypeId: 0,
      ruleForm: {
        id: "",
        name: "",
        levelOneTypeId: "",
        levelSecondTypeId: "",
        serverStartTime: "",
        serverEndTime: "",
        ak: "",
        sk: "",
        status: "",
      },
      /*pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },*/
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      value2: "",
      bigOptions: [],
      midOptions: [],
      options: [
        {
          id: 0,
          value: "选项1",
          label: "关闭",
        },
        {
          id: 1,
          value: "选项2",
          label: "进行中",
        },
        {
          id: 2,
          value: "选项3",
          label: "已上线",
        },
        {
          id: 3,
          value: "选项4",
          label: "异常",
        },
      ],
      status: "",
      value: "",
      value3: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    rTime(date) {
      var json_date = new Date(date).toJSON();
      return new Date(new Date(json_date) + 8 * 3600 * 1000)
          .toISOString()
          .replace(/T/g, " ")
          .replace(/\.[\d]{3}Z/, "");
    },
    //中类别选项
    getMidTypeList() {
      this.$api.get(
          "/type/mid/list",
          null,
          {
            list: false
          },
          (successRes) => {
            console.log(successRes);
            this.midOptions = successRes.data.rows;
            // console.log(" type="+this.midOptions)
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    //大类别选项
    getBigTypeList() {
      this.$api.get(
          "/type/big/list",
          null,
          {
            list: false
          },
          (successRes) => {
            console.log(successRes);
            this.bigOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    //输入框数据显示
    getUserInfoById(id) {
      this.$api.get(
          "/customer/getById",
          null,
          {id: id},
          (successRes) => {
            console.log(successRes);
            this.ruleForm = successRes.data;
            this.ruleForm.serverStartTime = new Date(
                successRes.data.serverStartTime
            );
            this.ruleForm.serverEndTime = new Date(successRes.data.serverEndTime);
            this.bigTypeId = successRes.data.levelOneTypeId;
            this.midTypeId = successRes.data.levelSecondTypeId;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    init() {
      console.log("哈哈哈" + this.$route.params.id);
      const userId = this.$route.query.id;
      this.getUserInfoById(userId);
      this.getBigTypeList();
      this.getMidTypeList();
    },
    //提交按钮
    editCustomer(ruleForm) {
      // console.log(this.$refs[ruleForm]);
      this.$nextTick(() => {
        this.$refs[ruleForm].validateField("street");
      });
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$api.post(
              "/customer/update",
              null,
              {
                id: this.ruleForm.id,
                name: this.ruleForm.name,
                // serverStartTime: this.ruleForm.serverStartTime,
                // serverEndTime: this.ruleForm.serverEndTime,
                serverStartTime: this.rTime(this.ruleForm.serverStartTime),
                serverEndTime: this.rTime(this.ruleForm.serverEndTime),
                levelOneTypeId: this.bigTypeId,
                levelSecondTypeId: this.midTypeId,
                ak: this.ruleForm.ak,
                sk: this.ruleForm.sk,
                status: this.ruleForm.status,
              },
              (successRes) => {
                console.log(successRes);
                if (successRes.status == 200) {
                  this.$message("操作成功");
                  this.$router.push({name: "customerList"});
                } else {
                  this.$message(successRes.message);
                }
              },
              (failureRes) => {
                this.$message("操作失败");
              }
          );
        } else {
          console.log("error submit!!");
          this.$message("输入数据不正确！");
          return false;
        }
      });
    },
  },
};
</script>